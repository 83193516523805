.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
  width: 100%; }
.row {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto; }
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; }
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
.no-scroll {
  overflow: hidden;
  min-height: calc(100vh - 64px); }
.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2; }
.top-margin {
  height: 64px; }
.room-banner {
  height: 64px;
  font-size: 75%;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  width: calc(90vw - 20px);
  img {
    margin: 2px;
    height: 50px; }
  .left-icons, .right-icons {
    display: flex;
    align-items: center; } }
.cover-screen {
  z-index: 10;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.94);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  &.cover-screen-light {
    background: rgba(0,0,0,0.85); } }
