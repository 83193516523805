body {
  background-color: white;
  height: 100vh;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  font-family: $font-default; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }
