@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }


@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100vw); }
  100% {
    transform: translateX(0); } }
@keyframes slideInFromRight {
  0% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0); } }
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100vh); }
  100% {
    transform: translateY(0); } }
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100vh); }
  100% {
    transform: translateY(0); } }

@keyframes slideLeft {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-100vw);
    display: none; } }
@keyframes slideRight {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(100vw);
    display: none; } }
@keyframes slideUp {
  0% {
    transform: translateY(-0); }
  100% {
    transform: translateY(-100vh); } }
@keyframes slideDown {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(100vh); } }


@keyframes crossZoomIn {
  0% {
    transform: scale(10); }
  100% {
    transform: scale(1); } }
@keyframes crossZoomOut {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(10);
    opacity: 0; } }
.cross-zoom-in {
  animation: 300ms cubic-bezier(.15,1,.85,1) 0s 1 crossZoomIn; }
.cross-zoom-out {
  animation: 300ms cubic-bezier(.7,.05,.92,.53) 0s 1 forwards crossZoomOut; }


.fade-in {
  animation: 1000ms linear 0s 1 fadeIn forwards; }
.fade-out {
  animation: 1000ms linear 0s 1 fadeOut forwards; }
.fade-in-quickly {
  animation: 300ms linear 0s 1 fadeIn forwards; }
.fade-out-quickly {
  animation: 300ms linear 0s 1 fadeOut forwards; }



.slide-in-from-top {
  animation: 1000ms ease-out 0s 1 slideInFromTop; }
.slide-in-from-bottom {
  animation: 1000ms ease-out 0s 1 slideInFromBottom; }
.slide-in-from-left {
  animation: 1000ms ease-out 0s 1 slideInFromLeft; }
.slide-in-from-right {
  animation: 1000ms ease-out 0s 1 slideInFromRight; }


.slide-up {
  animation: 1000ms ease-in 0s 1 slideUp forwards; }
.slide-down {
  animation: 1000ms ease-in 0s 1 slideDown forwards; }
.slide-left {
  animation: 1000ms ease-in 0s 1 slideLeft forwards; }
.slide-right {
  animation: 1000ms ease-in 0s 1 slideRight forwards; }
