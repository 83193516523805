.message-popup {
  position: fixed;
  background-color: white;
  border-radius: 5px;
  color: #555;
  width: 300px;
  max-height: 100px;
  left: calc(50vw - 150px);
  top: 9px;
  font-size: 12pt;
  word-break: break-word;
  text-align: left;
  padding: 6px 16px;
  cursor: pointer;
  border: 2px black solid;
  overflow: hidden;
  .chat-name {
    color: #2233dd;
    font-size: 10pt;
    line-height: 1;
    padding-left: 2px; }
  .chat-message {
    font-size: 13pt;
    line-height: 1.1; } }
.chat {
  position: relative;
  height: 34px;
  width: 64px;
  cursor: pointer;
  i {
    font-size: 32px; }
  .counter {
    color: white;
    border-radius: 50%;
    width: 26px;
    height: 22px;
    line-height: 22px;
    font-size: 13px;
    position: absolute;
    right: -2px;
    bottom: -8px;
    &.no-highlight {
      background-color: #333; }
    &.highlight {
      background-color: #f00; } } }
.chat-list {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  padding-top: 50px;
  textarea {
    width: 95vw;
    max-width: 700px;
    font-size: 18px;
    padding: 4px 8px;
    height: 82px;
    margin-bottom: 12px; }
  .chat-posts {
    height: calc(95vh - 170px);
    width: 95vw;
    max-width: 1000px;
    padding: 8px calc(2vw + 8px);
    border-radius: 10px;
    overflow-y: auto;
    position: relative;
    background-color: rgba(0, 0, 0, 0.7);
    .chat-post {
      padding: 4px 2px 8px;
      width: 100%;
      text-align: left; }
    .chat-name {
      color: #cfcc30;
      font-size: 10pt;
      line-height: 1.1;
      padding-left: 2px; }
    .chat-message {
      color: #f0f0f0;
      font-size: 14pt;
      line-height: 1.2; } }
  .new-messages {
    position: absolute;
    top: calc(95vh - 140px);
    width: 100vw;
    display: flex;
    justify-content: center;
    .new-messages-btn {
      background-color: #f0f0f0;
      color: #111;
      font-size: 12pt;
      padding: 2px 16px;
      border-radius: 16px;
      i {
        position: relative;
        top: 2px;
        padding-left: 3px; } } } }
