
// Screen Sizes
$xs: 360px;
$sm: 640px;
$md: 800px;
$lg: 1040px;
$xl: 1400px;

// Fonts
$font-default: 'Nunito', verdana, arial, sans-serif;
$font-fun: 'Francois One', impact, arial, sans-serif;

// colors
$brand-bg:      #f0f0ff;
$brand-dark:    #303544;
$brand-light:   #e4e6e9;
$brand-white:   #f6f7f9;
$brand-blue:    #3fa9f5;
